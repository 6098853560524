const NP_SERVICE_DATA_PROPERTY_NAME = "data";
const URL_PARAMETER_DATA_KEY = "data";
const URL_PARAMETER_SESSION_ID = "sessionId";
const URL_PARAMETER_EMAIL_ID = "emailId";

const ENV_KEYS = {
  NP_SERVICE_ENDPOINT : "np_service_endpoint"
}

const SCREEN_MESSAGES = {
    DEFAULT : "We're sorry to see you go!",
    YES : "You have successfully unsubscribed!",
    NO : "We're glad you changed your mind!",
    ERROR : "There was an error processing your request, please try again later!"
};

const SCREENS = {
    DEFAULT : 0,
    YES_SCREEN : 1,
    NO_SCREEN : 2,
    ERROR_SCREEN : 3
}

const ACTIONS = {
    YES_CLICKED : 1,
    NO_CLICKED : 2
}

export {
    NP_SERVICE_DATA_PROPERTY_NAME,
    URL_PARAMETER_DATA_KEY,
    SCREENS,
    ACTIONS,
    SCREEN_MESSAGES,
    ENV_KEYS,
  URL_PARAMETER_SESSION_ID,
  URL_PARAMETER_EMAIL_ID
};
