import React from "react";

import s from './style.module.scss';
import walmartLogo from '../../Images/walmartLogo.png';

//export default function Header(){
function Header(){
return <div className={s.background}> 
    <img src={walmartLogo}  className={s.image}/>
</div>
}

export default Header;

// module.export = {
//     Header : Header
// }