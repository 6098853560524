import CONFIGS from '../Configurations';
import {ENV_KEYS, URL_PARAMETER_DATA_KEY} from '../utils/constants';
import PulseService from '../services/PulseService';
let unsubscribeNotification = async function unsubscribeNotification(paramData){
    paramData[URL_PARAMETER_DATA_KEY] = atob(paramData[URL_PARAMETER_DATA_KEY]);

    let response = await fetch(CONFIGS[ENV_KEYS.NP_SERVICE_ENDPOINT],{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          ...PulseService.getCustomDimensions()
        },
        body: JSON.stringify(paramData)
    });
    if(response.status != "200"){
     return Promise.reject(`received a non 200 status : ${response.status}`)
    }
    return response;
}

export { unsubscribeNotification};