import React, { useEffect } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import queryString from 'query-string';

import "./App.css";
import UnsubscribeConfirmationPage from "./pages/UnsubscribeConfirmationPage";
import SessionService from './services/SessionService';
import PulseService from './services/PulseService';
import { URL_PARAMETER_SESSION_ID, URL_PARAMETER_EMAIL_ID } from './utils/constants';
import Configs from './Configurations';

function App() {
  useEffect(() => {
    SessionService.createSession();
    PulseService.initialize(Configs.REACT_APP_NAME, () => {
      let queryParams = queryString.parse(window.location.href);
      return { 
        [URL_PARAMETER_SESSION_ID]: SessionService.getSessionId(),
        [URL_PARAMETER_EMAIL_ID]: queryParams[URL_PARAMETER_EMAIL_ID]
      }
    });
  }, []);

  return (
    <Router>
      <div>
        <Route
          path="/"
          render={props => <UnsubscribeConfirmationPage {...props} />}
        />
      </div>
    </Router>
  );
}

export default App;
