import React, { useState, Fragment } from "react";

import s from "./style.module.scss";

import {
  SCREENS,
  ACTIONS,
  SCREEN_MESSAGES,
} from "../../utils/constants";
import whatToShow from "../../services/SubscriptionContainerLogic";

import Smiley from "../Smiley";
import ConfirmationButtons from "../ConfirmationButtons";

import sadEnvelop from "../../Images/sadEnvelop.svg";
import happyEnvelop from "../../Images/happyEnvelop.svg";

import PulseService from "../../services/PulseService";

export default function Container(props) {
  let [showScreen, setShowScreen] = useState(SCREENS.DEFAULT);

  async function yesOnClick() {
    setShowScreen(await whatToShow(ACTIONS.YES_CLICKED, props));
  }

  async function noOnClick() {
    setShowScreen(await whatToShow(ACTIONS.NO_CLICKED));
  }

  return (
    <div className={s.container}>
      {showScreen === SCREENS.DEFAULT && (
        <Fragment>
          <Smiley text={SCREEN_MESSAGES.DEFAULT} imgSrc={sadEnvelop} />
          <ConfirmationButtons yesOnClick={yesOnClick} noOnClick={noOnClick} />
        </Fragment>
      )}

      {showScreen === SCREENS.YES_SCREEN && (
        <Fragment>
          <Smiley text={SCREEN_MESSAGES.YES} imgSrc={sadEnvelop} />
        </Fragment>
      )}

      {showScreen === SCREENS.NO_SCREEN && (
        <Fragment>
          <Smiley text={SCREEN_MESSAGES.NO} imgSrc={happyEnvelop} />
        </Fragment>
      )}

      {showScreen === SCREENS.ERROR_SCREEN && (
        <Fragment>
          <Smiley text={SCREEN_MESSAGES.ERROR} imgSrc={sadEnvelop} />
        </Fragment>
      )}
    </div>
  );
}
