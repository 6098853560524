import React from "react";
import s from "./style.module.scss"

export default function ConfirmationButtons(props){ 
    return <div className = {s.confirmationContaier}> 
        <p className={s.confirmationMessage}> Are you sure you want to unsubscribe?</p>
        <div className={s.confirmationButtonContainer}> 
            <button className={s.yesButton} onClick={props.yesOnClick}>
                Yes
            </button> 
            <button className={s.noButton} onClick={props.noOnClick}>
                No
            </button>
        </div>
    </div>
} 
