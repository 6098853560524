import React, { Fragment } from "react";
import Header from "../components/Header";
import Container from "../components/Container";
import Footer from "../components/Footer";

export default function UnsubscribeConfirmationPage(props) {
  return (
    <Fragment>
      <Header />
      <Container {...props} />
      <Footer />
    </Fragment>
  );
}
