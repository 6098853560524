
import {ACTIONS, SCREENS} from "../utils/constants";
import { unsubscribeNotification } from "./SubscriptionManagerService"
import queryString from 'query-string';

export default async function whatToShow(action, props){
    if(action === undefined){
        return SCREENS.DEFAULT;
    }
    else if(action === ACTIONS.YES_CLICKED){
        try{
            let queryParams = queryString.parse(props.location.search)
            await unsubscribeNotification(queryParams);
            return SCREENS.YES_SCREEN;
        }
        catch(error){
            console.error("Error occurred at unsubscribe request :", error);
            return SCREENS.ERROR_SCREEN;
        }
    }
    else if(action === ACTIONS.NO_CLICKED){
        return SCREENS.NO_SCREEN;
    }
}