import { v4 as uuidv4 } from 'uuid';
import CookieService from './CookieService';

const USER_SESSION_KEY = 'user_session_id';

class SessionService {
  static clearSession = () => {
    CookieService.clearCookie(USER_SESSION_KEY);
  };

  static createSession = () => {
    let userSessionId = CookieService.getCookie(USER_SESSION_KEY);
    // create a FE session key if it doesn't exists
    if (!userSessionId) {
      let newUserSessionId = uuidv4();
      CookieService.setCookie(USER_SESSION_KEY, newUserSessionId);
    }
  };

  static getSessionId = () => {
    return CookieService.getCookie(USER_SESSION_KEY);
  };
}

export default SessionService;
